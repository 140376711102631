@import '../../App/App.scss';

#feed-back-requests {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.fixed-page-top {
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-results {
    color: $congo-pink;
    padding-top: 50px;
}

.payload-container {
    padding-top: 192px;
}

.scroll-with-modal-fix {
    position: fixed;
}