@import '../../../App/App.scss';

#footer {
    margin-top: auto;
    position: fixed;
    bottom: 0;
    align-self: flex-end; // Send footer to very bottom
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    width: 100%;
    padding: 5px 0px 5px 0px;
    background-color: $dark-charcoal;
}