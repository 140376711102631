@import '../../../App/App.scss';

.modal-container {
    position: absolute;
    top: 20%;
    z-index: 200;
    background-color: rgba($color: $dark-charcoal, $alpha: 1);
    max-width: 40%;
}

.modal-close-btn {
    margin-right: 0px;
    margin-left: auto;
}

.modal-transition-enter {}

.modal-transition-enter-active {
    position: fixed;
    transform: scale(1);
    opacity: 0;
    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modal-transition-exit {}

.modal-transition-exit-active {
    position: fixed;
    animation: quickScaleDown 0s .5s linear forwards;
    animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.backdop-transition-enter {}

.backdop-transition-enter-active {
    transform: scale(1);
    animation: fadeIn .5s ease-in forwards;
}

.backdop-transition-exit {
    backdrop-filter: none;
}

.backdop-transition-exit-active {
    animation: quickScaleDown 0s .5s linear forwards;
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

@keyframes fadeIn {
    0% {
        background: rgba(0, 0, 0, .0);
    }

    100% {
        background: rgba(0, 0, 0, .3);
    }
}

@keyframes fadeOut {
    0% {
        background: rgba(0, 0, 0, .3);
    }

    100% {
        background: rgba(0, 0, 0, .0);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(.8) translateY(1000px);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }

    100% {
        transform: scale(.8) translateY(1000px);
        opacity: 0;
    }
}

@keyframes scaleBack {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.85);
    }
}


@keyframes scaleForward {
    0% {
        transform: scale(.85);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes quickScaleDown {
    0% {
        transform: scale(1);
    }

    99.9% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@media (orientation: portrait),
(max-width: 980px) {
    .modal-container {
        max-width: 80%;
    }
}