@import '../../App/App.scss';

.request-item {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $charcoal;
    padding: 4px;
    user-select: none;
    width: 50vw;
    cursor: pointer;

    &:hover {
        filter: brightness(110%);

        & .trash-button {
            visibility: visible;
            width: auto;
        }

        & .qr-button {
            visibility: visible;
            width: auto;
        }

        & .trash-spinner {
            visibility: visible;
            width: auto;
        }

        & .list-icon {
            visibility: visible;
            width: auto;
        }
    }
}

.trash-button {
    visibility: hidden;
    width: 0px;
}

.trash-spinner {
    visibility: hidden;
    width: 0px;
}

.qr-button {
    visibility: hidden;
    width: 0px;
}

#qr-icon {
    width: 35px;
    height: 35px;
    background: url('../../res/images/qr-icon.svg') no-repeat right top;
}

.list-icon {
    visibility: hidden;
    width: 0px;
}

.feed-back-name {
    user-select: text;
    font-size: x-large;
    padding: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $magnolia;
    width: 100%;
    height: 100%;
}

#date-and-name {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.row-actions-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

@media (orientation: portrait),
(max-width: 980px) {
    .request-item {
        width: 90vw;

        & .trash-button {
            visibility: visible;
            width: auto;
        }

        & .qr-button {
            visibility: visible;
            width: auto;
        }

        & .list-icon {
            visibility: visible;
            width: auto;
        }
    }

    .feed-back-name {
        user-select: text;
        font-size: large;
        order: 1;
        padding: 5px;
    }

    #date-and-name {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .row-actions-container {
        flex-direction: row-reverse;
    }
}