@import '../../App/App.scss';

.feedbackform-subject-name {
    font-size: larger;
    color: $magnolia;
    word-wrap: break-word;
    white-space: wrap;
}

.feedbackform-subject-numeric-results {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    color: $magnolia;
}

.subject-list-item {
    background-color: $charcoal;
    border-radius: 5px;
    padding: 15px;
}