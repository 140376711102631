@import "../../../App/App.scss";

.info-section-description {
  font-size: 30px;
  text-align: center;
  width: 50vw;
}

.info-section-warning-description {
  font-size: 17px;
  text-align: center;
  width: 50vw;
  color: $congo-pink;
}

//required when header is visible in login state
.space {
  height: 150px;
  width: 50vw;
}

@media (orientation: portrait),
(max-width: 980px) {
  .info-section-description {
    width: 90vw;
  }

  .info-section-warning-description {
    width: 90vw;
  }
}