@import '../../../App/App.scss';

.validation-error {
    background-color: $error-red;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    padding: 20px;
    color: white;
    font-size: large;
    transform: translateX(-100%) translateX(-10px);
    max-width: 20vw;
    z-index: 100;
}

.validation-error::after {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: $error-red;
    clip-path: polygon(0 0, 0 100%, 40% 50%);
    right: -24px;
    top: 15px;
}


.validity-container {
    display: flex;
    flex-direction: row;
}

@media (orientation: portrait),
(max-width: 980px) {
    .validation-error {
        transform: translateY(-100%) translateY(-10px);
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: 80vw;
    }

    .validation-error::after {
        background-color: $error-red;
        clip-path: polygon(0 0, 50% 50%, 100% 0);
        left: 40%;
        top: 99%;
    }
}