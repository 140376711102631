$dark-charcoal: rgb(35, 45, 53);
$charcoal: #364652;
$lavender-gray: #cac4ceff;
$beige: #eeebd0ff;
$magnolia: #F8F0FB;
$yellow-crayola: #fff07cff;
$congo-pink: #fe938cff;
$error-red: rgb(206, 71, 61);
$granny-smith-apple: #90e39aff;
$cerulean-crayola: #05b2dcff;
$fbc-blue: rgb(5, 116, 220);

:root {
  --bunu-dene: orange;
}

:export {
  darkCharcoal: $dark-charcoal;
  charcoal: $charcoal;
  lavenderGray: $lavender-gray;
  yellowCrayola: $yellow-crayola;
  ceruleanCrayola: $cerulean-crayola;
  congoPink: $congo-pink;
  grannySmithApple: $granny-smith-apple;
  beige: $beige;
  magnolia: $magnolia;
  fbcBlue: $fbc-blue;
  errorRed: $error-red;
}

body {
  background-color: black;
}

input:focus::placeholder {
  color: transparent;
}

.text-input {
  width: 100%;
  font-size: large;
  border-radius: 5px;
  border-style: none;
  padding: 15px;
  box-sizing: border-box;
  border: solid 1px $lavender-gray;
  text-align: center;

  &::placeholder {
    font-family: 'Roboto', sans-serif;
  }

  &:focus {
    outline: none;
    border: solid 1px $fbc-blue;
  }
}

.text-input.invalid {
  border: solid 1px $error-red;
  color: $error-red;
  background: url("../res/images/exclamation-solid.svg") no-repeat right white;
  background-size: 23px 23px;
}

.std-text {
  font-family: 'Roboto', sans-serif;

  &::selection {
    background-color: $cerulean-crayola;
    /* WebKit/Blink Browsers */
    color: white;
  }
}

.neon-text {
  font-family: 'Send Flowers', cursive;
  font-size: xx-large;
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(0, 255, 60),
    0 0 82px rgb(0, 255, 60),
    0 0 92px rgb(0, 255, 60),
    0 0 102px rgb(0, 255, 60),
    0 0 151px rgb(0, 255, 60);

  user-select: none;
  text-align: center;
}

@media (orientation: portrait),
(max-width: 500px) {
  .neon-text {
    font-size: large;
  }
}