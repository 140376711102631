.month {
    font-size: medium;
    color: inherit;
}

.day {
    font-size: xx-large;
    color: inherit;
}

.year {
    font-size: large;
    color: inherit;
}

@media (orientation: portrait),
(max-width: 980px) {
    .calender-view {
        visibility: hidden;
        width: 0px;
    }
}