.description-input {
    width: 35vw;
    font-family: 'Roboto', sans-serif;
}

@media (orientation: portrait),
(max-width: 980px) {
    .description-input {
        width: 90vw;
    }
}