@import "../../../App/App.scss";

.bulk-section-description {
  font-size: 30px;
  text-align: center;
  width: 50vw;
}

@media (orientation: portrait),
(max-width: 980px) {
  .bulk-section-description {
    width: 90vw;
  }
}