@import "../../../App/App.scss";

#full-page-section {
  background-color: $dark-charcoal;
  color: $lavender-gray;
  min-height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  gap: 30px;
  padding: 30px;
}