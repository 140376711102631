@import '../../App/App.scss';

.list-controls {
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 10px 9px 10px 9px;
    background-color: $dark-charcoal;
    width: 50vw;
}

.filter-select {
    background: url("../../res/images/filter-solid.svg") no-repeat left $dark-charcoal;
    background-size: 20px 20px;
    background-position-x: 10px;
    flex-shrink: 2;
    min-width: 0;
}

.sort-select {
    background: url("../../res/images/sort-solid.svg") no-repeat left $dark-charcoal;
    background-size: 25px 25px;
    background-position-x: 10px;
    flex-shrink: 2;
    min-width: 0;
}

.add-new-button {
    //border-radius: 50%;
}

@media (orientation: portrait),
(max-width: 980px) {
    .list-controls {
        width: 90vw;
    }
}