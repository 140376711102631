@import '../../App/App.scss';

.main-page-container {
    position: fixed;
    width: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.go-to-my-requests-btn {
    position: relative;
    top: 25px;
    background-color: $granny-smith-apple;
    color: black;
}