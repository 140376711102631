@import '../../../App/App.scss';

.selection-box{
    font-size: x-large;
    padding: 15px 15px 15px 35px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: 0px;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    user-select: none;
    &:hover{
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:active{
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.selection-box option{
    background-color: $dark-charcoal;
    &:hover{
        background-color: rgba(255, 255, 255, 0.1);
    }
}
