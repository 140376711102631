@import '../../../App/App.scss';

.dynamic-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
}

.index-text {
    font-size: x-large;
    color: white;
}

.item-delete-btn {
    border-radius: 50%;
    height: max-content;
    background-color: $error-red;
}