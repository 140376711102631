@import '../../../App/App.scss';

.search-field {
    background-color: transparent;
    color: white;
    border: none;
    font-size: x-large;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    flex-shrink: 1;
    min-width: 0;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:focus {
        outline: none;
    }

    &:focus::placeholder {
        color: gray;
    }

    &::placeholder {
        font-family: 'Roboto', sans-serif;
        text-align: center;
        color: white;
    }
}

.search-bar {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    flex-shrink: 1;
    min-width: 0;
}

.search-icon {
    margin-left: 5px;
    padding: 10px;
    border-radius: 50%;
}

.cancel-icon {
    margin-right: 5px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}