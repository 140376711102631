@import'../../App/App.scss';

.give-feedback-page-container {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    gap: 15px;
    padding-top: 20px;
    //width: 100vw;
}

.give-feedback-form-container {
    width: 50%;
}

.give-feedback-submit {
    margin-bottom: 20px;
}

.expired-text {
    text-align: center;
    font-size: x-large;
    color: $congo-pink;
    width: 50%;
}

.disclaimer {
    text-align: center;
    color: pink;
    padding-bottom: 10px;
    width: 50%;
}

.disclaimer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.success-message {
    font-size: medium;
    color: $granny-smith-apple;
}

.thank-you-text {
    font-size: 60px;
}

@media (orientation: portrait),
(max-width: 980px) {
    .disclaimer {
        width: 90%;
    }

    .give-feedback-form-container {
        width: 90%;
    }
}