@import '../../../App/App.scss';

.std-btn {
  display: block;
  border-radius: 5px;
  border-style: none;
  flex-shrink: 0.1;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(117%);
  }

  cursor: pointer;
}

.primary-button {
  background-color: $fbc-blue;
  font-size: large;
  color: white;
  padding: 15px;
}

.secondary-button {
  background-color: $cerulean-crayola;
  color: white;
  font-size: large;
  padding: 5px;
}

.tertiary-button {
  background-color: transparent;
  font-size: medium;
  padding: 7px;
  width: auto;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}