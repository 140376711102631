.feed-back-count-icon {
    padding: 4px;
}

.feed-back-count {
    font-size: xx-large;
}

.avg-score {
    font-size: xx-large;
}

.avg-score-icon {
    padding: 4px;
}

@media (orientation: portrait),
(max-width: 980px) {

    .feed-back-count {
        font-size: x-large;
    }

    .avg-score {
        font-size: x-large;
    }
}