@import '../../../App/App.scss';

.std-datetime-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $dark-charcoal;
    color: $beige;
    width: 4em;
    height: 4em;
    padding: 10px;

    &:hover {
        filter: brightness(120%);
    }
}

.std-datetime-box.expired {
    color: $congo-pink;
}

.remaining-time {
    position: absolute;
    top: 0;

    &:hover {
        opacity: 0;
    }
}

@media (orientation: portrait),
(max-width: 980px) {
    .std-datetime-box {
        flex-direction: row;
        background-color: $charcoal;
        width: 100%;
        height: 20px;
        gap: 5px;
        justify-content: flex-start;

        &:hover {
            filter: none;
        }
    }

    .remaining-time {
        &:hover {
            opacity: 100;
        }
    }
}