@import '../../../App/App.scss';

.feedback-section-text {
    font-size: 30px;
    width: 60%;
    text-align: center;
}

@media (orientation: portrait),
(max-width: 980px) {
    .feedback-section-text {
        width: 90vw;
    }
}