@import '../../App/App.scss';

#login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: $magnolia;
  padding: 10px;
  position: relative;
}

.login-button {
  width: 100%;
}

.sign-up-button {
  width: 100%;
}

.login-title {
  padding: 0px;
}

.ghost-error-message {
  position: absolute;
  top: -50px;
  font-size: larger;
  z-index: 2000;
  color: $error-red;
}