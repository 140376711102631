@import '../../../App/App.scss';

#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 50vw;
    padding: 15px;
    background-color: black;
}

@media (orientation: portrait),
(max-width: 980px) {
    #header {
        width: 90vw;
    }
}