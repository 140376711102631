@import '../../App/App.scss';

.wisard-layout {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.wisard-page-header {
    position: fixed;
}

.wisard-buttons {
    display: flex;
    width: 50vw;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 40px;
}

.back-button {
    width: 38%;
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%);
    user-select: none;
}

.next-button {
    width: 38%;
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    user-select: none;
}

.wisard-description {
    color: $beige;
    font-size: x-large;
    width: 50vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-line;
}

.description-container {
    margin-top: 120px;
    padding: 30px;
}

.cancel-button {
    background-color: $error-red;
    width: 20%;
    user-select: none;

    &:disabled {
        visibility: hidden;
    }
}

@media (orientation: portrait),
(max-width: 980px) {
    .wisard-buttons {
        width: 90vw;
    }

    .wisard-description {
        width: 70vw;
    }
}