.expire-date {
    flex-shrink: 0;
    position: relative;
}


@media (orientation: portrait),
(max-width: 980px) {
    .expire-date {
        order: 2;
        width: 100%;
    }
}