@import '../../App/App.scss';

.feedbackform {
    background-color: $dark-charcoal;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: medium;
    border-radius: 5px;
}

.feedbackform-topic {
    font-size: xx-large;
    color: $cerulean-crayola;
    white-space: wrap;
    word-wrap: break-word;
}

.feedbackform-topic.expired {
    color: $congo-pink;
}

.feedbackform-description {
    font-size: x-large;
    color: $lavender-gray;
    white-space: wrap;
    word-wrap: break-word;
}

.feedbackform-expire-date {
    font-size: large;
    color: $lavender-gray;
}

.feedbackform-expire-date.expired {
    color: $congo-pink;
}