@import "../../../App/App.scss";

#slogan {
  font-size: 45px;
  grid-area: slogan;
  text-align: center;
  width: 180px;
  line-height: 1.5;
}

#login-section-description {
  font-size: 30px;
  grid-area: desc;
  text-align: center;
  width: 180px;
  line-height: 1.5;
}



@media (orientation: portrait),
(max-width: 980px) {
  #login-section {
    order: 2;
  }

  #slogan {
    width: 90vw;
    font-size: 30px;
    text-align: center;
    order: 3;
  }

  #login-section-description {
    width: 90vw;
    font-size: 20px;
    text-align: center;
    order: 4;
  }
}