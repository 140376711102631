@import '../../../App/App.scss';

.std-link {
  color: $dark-charcoal;
  font-size: medium;
  width: max-content;

  &:hover {
    animation-name: decoration;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    cursor: pointer;
  }
}

@keyframes decoration {
  100% {
    color: $cerulean-crayola;
  }
}