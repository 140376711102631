@import '../../App/App.scss';

.feedbackform-container {
    width: 50vw;
    background-color: $magnolia;
    padding: 3px;
    // box-shadow:
    //     0 0 7px #fff,
    //     0 0 10px #fff,
    //     0 0 21px #fff,
    //     0 0 42px rgba(0, 255, 60, 0.362);
}

@media (orientation: portrait),
(max-width: 980px) {
    .feedbackform-container {
        width: 90vw;
    }
}